
import { IPortfolioSnapshot } from '@models/portfolio-snapshot';
import { ProductType } from '@models/product-type';
import { ITopic } from '@models/topic';
import { TopicActivity } from '@models/topic-activity';
import { createAction, props } from '@ngrx/store';

export enum PORTFOLIO_ACTIONS {
  loadSnapshots = '[Handwriting] Load Snapshots',
  loadTopics = '[Handwriting] Load Topics',
  loadTopic = '[Handwriting] Load Topic',
  setTopics = '[Handwriting] Set Topics',
  setTopic = '[Handwriting] Set Topic',
  setSnapshotsLoaded = '[Handwriting] Set Snapshots Loaded',
  setTopicsLoaded = '[Handwriting] Set Topics Loaded',
  setTopicLoaded = '[Handwriting] Set Topic Loaded',
  setSnapshots = '[Handwriting] Set Snapshots',
  removeSnapshot = '[Handwriting] Remove Snapshot',
  setMessages = '[Handwriting] Set Messages',
}

export const loadSnapshots = createAction(PORTFOLIO_ACTIONS.loadSnapshots, props<{ topicKey: string }>());
export const loadTopics = createAction(PORTFOLIO_ACTIONS.loadTopics, props<{
  classroomId: string,
  topicKey: string,
  productType: ProductType,
  unit: string,
}>());
export const loadTopic = createAction(PORTFOLIO_ACTIONS.loadTopic, props<{ topicKey: string }>());
export const setSnapshots = createAction(PORTFOLIO_ACTIONS.setSnapshots, props<{ snapshots: IPortfolioSnapshot[] }>());
export const setSnapshotsLoaded = createAction(PORTFOLIO_ACTIONS.setSnapshotsLoaded,
  props<{ snapshotsLoaded: boolean }>());
export const removeSnapshot = createAction(PORTFOLIO_ACTIONS.removeSnapshot, props<{ snapshot: IPortfolioSnapshot }>());
export const setTopics = createAction(PORTFOLIO_ACTIONS.setTopics, props<{ topics: ITopic[] }>());
export const setTopic = createAction(PORTFOLIO_ACTIONS.setTopic, props<{ topic: TopicActivity }>());
export const setTopicsLoaded = createAction(PORTFOLIO_ACTIONS.setTopicsLoaded, props<{ topicsLoaded: boolean }>());
export const setTopicLoaded = createAction(PORTFOLIO_ACTIONS.setTopicLoaded, props<{ topicLoaded: boolean }>());
export const setMessages = createAction(PORTFOLIO_ACTIONS.setMessages, props<{ messages: string[] }>());
