
import { IPortfolioSnapshot } from '@models/portfolio-snapshot';
import { ITopic } from '@models/topic';
import { TopicActivity } from '@models/topic-activity';
import { createReducer, on, Action } from '@ngrx/store';

import {
  loadSnapshots,
  loadTopic,
  loadTopics,
  removeSnapshot,
  setMessages,
  setSnapshots,
  setSnapshotsLoaded,
  setTopic,
  setTopics,
  setTopicsLoaded,
  setTopicLoaded,
} from './portfolio.actions';

export const handwritingPortfolioStateFeatureKey = 'handwritingPortfolioState';

export interface HandwritingPortfolioState {
  snapshotsLoaded: boolean;
  topicsLoaded: boolean;
  topicLoaded: boolean;
  topics: ITopic[];
  topic: TopicActivity;
  snapshots: IPortfolioSnapshot[];
  messages: string[];
}

export const initialState: HandwritingPortfolioState = {
  snapshotsLoaded: false,
  topicsLoaded: false,
  topicLoaded: false,
  topics: null,
  topic: null,
  snapshots: null,
  messages: null,
};

export const portfolioReducers = createReducer(
  initialState,
  on(loadSnapshots, state => ({ ...state, snapshotsLoaded: false, snapshots: null })),
  on(loadTopics, state => ({ ...state, topicsLoaded: false, topics: null, topic: null, snapshots: null })),
  on(loadTopic, state => ({ ...state, topicLoaded: false, topic: null })),
  on(setSnapshotsLoaded, (state, { snapshotsLoaded }) => ({ ...state, snapshotsLoaded })),
  on(setTopicsLoaded, (state, { topicsLoaded }) => ({ ...state, topicsLoaded })),
  on(setTopicLoaded, (state, { topicLoaded }) => ({ ...state, topicLoaded })),
  on(setSnapshots, (state, { snapshots }) => ({ ...state, snapshots })),
  on(removeSnapshot, (state, { snapshot }) => ({
    ...state,
    snapshots: state.snapshots.filter(s => s.activityId !== snapshot.activityId),
  })),
  on(setTopics, (state, { topics }) => ({ ...state, topics })),
  on(setTopic, (state, { topic }) => ({ ...state, topic })),
  on(setMessages, (state, { messages }) => {
    const hasMessage = state.messages ? messages.findIndex(m => state.messages.includes(m)) !== -1 : false;
    if (state.messages && !hasMessage) {
      return { ...state, messages: state.messages.concat(messages) };
    }
    return { ...state, messages };
  }),
);

export function reducer(state: HandwritingPortfolioState | undefined, action: Action) {
  return portfolioReducers(state, action);
}
